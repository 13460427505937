
















@import '@design';

.bubs-digital-layout {
  .grid {
    padding: 1em;
    height: 100vh;
  }
  .menu-section {
    margin-bottom: 1.3em;
    .menu-section--top {
      margin-bottom: 0.6em !important;
    }
    .menu-section--title {
      font-size: 2.4em;
    }
    .menu-section--subtitle .resto-editable {
      font-size: 1.1em;
    }
    // .menu-item {
    //   font-size: 1.8em;
    // }

    .menu-item--title,
    .menu-item--prices {
      font-size: 1.55em;
    }

    .menu-item--description {
      font-size: 1.4em;
      line-height: 1.2;
    }

    .item-addon {
      font-size: 1em;
    }
  }

  &:not(.menu-editing-on) {
    .grid {
      color: white;
      background-color: darken($color-bubs-purple, 27);
    }
    .menu-element--dots {
      border-bottom-color: white;
    }
    .menu-section {
      &.callout {
        border-color: #fff;
      }
    }
  }
}
